import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "support",
    path: "accueil / nos métiers / support / coordinateur"
  },
  title: "Coordinateur", 
	missions:[
		{
		text: "Coordonner une production informatique" 
		},
		{
		text: "Assurer le respect des procédures et des fondamentaux métiers" 
		},
		{
		text: "Produire les statistiques associées" 
		},
		{
		text: "Suivre et veiller au respect des différents engagements de service" 
		},
		{
		text: "Être le référent de l’équipe" 
		},
	],

	key_skills: [
		{
		text: "Organiser" 
		},
		{
		text: "Etre consciencieux" 
		},
		{
		text: "Analyser et comprendre" 
		},
		{
		text: "Collaborer" 
		},
	],
	prerequisites:[
		{
		text: "Maitrise des fondamentaux métiers" 
		},
		{
		text: "Maîtrise des outils bureautiques" 
		},
		{
		text: "Bac + une formation qualifiante en informatique et/ou une expérience minimum d’un an sur poste équivalent (recrutement externe)" 
		},
		{
		text: "Minimum 2 ans sur poste de TSP ou TSD confirmé (évolution interne)" 
		},
	],
	activities:[
		{
		text: "Planifier l’équipe et coordonner les actions de l’équipe" 
		},
		{
		text: "Assurer l’application des fondamentaux métiers" 
		},
		{
		text: "Faire appliquer les procédures et consignes" 
		},
		{
		text: "Intégrer et accompagner les nouveaux collaborateurs" 
		},
		{
		text: "Suivre les indicateurs qualitatifs et quantitatifs de l’activité de l’équipe" 
		},
		{
		text: "Documenter et faire évoluer les procédures" 
		}
	]
}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;